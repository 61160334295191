<template>
  <div style="width: 100%">
    <a-space direction="vertical" size="large" style="width: 100%">
      <!-- 类型按钮 -->
      <a-select
        :default-value="questionDetail.type_display"
        v-model="questionDetail.type_display"
        style="width: 120px"
        @change="changType"
      >
        <a-select-option value="1">单选</a-select-option>
        <a-select-option value="2">多选</a-select-option>
        <a-select-option value="3">填空</a-select-option>
      </a-select>

      <!-- 选择题 -->
      <a-space
        direction="vertical"
        size="large"
        v-if="[1, 2].includes(questionDetail.type)"
        style="width: 100%"
      >
        <a-card style="width: 100%">
          <h1>{{ questionDetail.question }}</h1>
          <!-- 单选 -->
          <a-radio-group v-if="questionDetail.type == '1'">
            <a-radio
              :style="radioStyle"
              v-for="(item, index) in questionDetail.answer_list"
              :value="item.title"
              :key="index"
            >
              {{ item.title }}
            </a-radio>
          </a-radio-group>
          <!-- 多选 -->
          <a-space direction="vertical" v-if="questionDetail.type == '2'">
            <a-checkbox
              v-for="(item, index) in questionDetail.answer_list"
              :key="index"
            >
              {{ item.title }}
            </a-checkbox>
          </a-space>
        </a-card>

        <a-card style="width: 100%">
          <a-space direction="vertical" size="middle">
            <a-button type="primary" @click="addAnswer()">添加选项</a-button>
            <a-input
              placeholder="请输入题目"
              v-model="questionDetail.question"
              style="width: 300px"
              @click="(e) => e.currentTarget.select()"
            />
            <a-space direction="vertical" size="small">
              <a-space
                v-for="(item, index) in questionDetail.answer_list"
                :key="index"
              >
                <a-input
                  style="width: 300px"
                  type="text"
                  v-model="item.title"
                  @click="(e) => e.currentTarget.select()"
                />
                <a-button
                  type="primary"
                  @click="del(index)"
                  shape="circle"
                  icon="minus"
                />
                <a-button
                  type="primary"
                  @click="up(index)"
                  shape="circle"
                  icon="arrow-up"
                />
                <a-button
                  type="primary"
                  @click="down(index)"
                  shape="circle"
                  icon="arrow-down"
                />
                <a-checkbox v-model="item.attention_status" >是否提醒关注</a-checkbox>
                <a-textarea v-model="item.suggestion" placeholder="建议" auto-size />
                <h1>设置分值</h1>
                <a-input-number v-model="item.score" :min="-100" :max="100" />
                <h1>分</h1>
                <a-select v-model="item.toTypeName" style="width: 120px">
                  <a-select-option value="无关联" @click="showModel(item, 0, index)">
                    无关联
                  </a-select-option>
                  <a-select-option value="跳题逻辑" @click="showModel(item, 1, index)">
                    跳题逻辑
                  </a-select-option>
                  <a-select-option value="选项关联" @click="showModel(item, 2, index)">
                    选项关联
                  </a-select-option>
                </a-select>
                <a-tag
                  closable
                  v-if="item.to_id_name"
                  @close="(e) => hangdleTag(e, index)"
                  >{{ item.to_id_name }}</a-tag>
              </a-space>
            </a-space>
            <a-button type="primary" @click="info()">保存</a-button>
          </a-space>
        </a-card>
      </a-space>

      <!-- 填空题 -->
      <div class="question" v-if="questionDetail.type == '3'">
        <a-input
          v-model="questionDetail.question"
          placeholder="请输入题目"
          style="width: 300px"
        />
        <a-button type="primary" @click="info()">保存</a-button>
      </div>
    </a-space>

    <!-- 弹窗 s -->
    <a-modal
      :title="isBounceNode ? '选项关联' : '跳题逻辑'"
      :visible="isShowModel"
      @ok="setConnect"
      @cancel="canceConnect"
      okText="确定"
      cancelText="取消"
    >
      <!-- <div>
        <input type="text">dd</input>
      </div> -->
      <div class="global-search-wrapper" style="width: 100%">
        <div style="width: 100%; display: flex; align-items: center">
          <a-auto-complete
            class="global-search"
            size="large"
            style="width: 100%"
            :placeholder="
              isBounceNode ? '请选择模块并输入关键字以搜索流程' : '选择问题'
            "
            option-label-prop="title"
            allowClear
            v-model="nodeInput"
            @search="inputChange"
            @focus="inputFocus"
          >
            <template slot="dataSource">
              <a-select-option
                @click="selectNode(item)"
                v-for="item in connectList"
                :key="item.title"
                :title="item.title"
              >
                <span className="global-search-item-count">{{
                  item.title
                }}</span>
              </a-select-option>
            </template>
          </a-auto-complete>
          <!-- <a class="a-tip">找不到想要的问题，去创建</a> -->
        </div>

        <div style="margin-top: 20px" v-if="isBounceNode">
          <a-radio-group
            v-model="modelValue"
            @change="() => (this.connectList = [])"
          >
            <a-radio value="evaluate">评估</a-radio>
            <a-radio value="diagnose">诊断</a-radio>
            <a-radio value="intervention">干预</a-radio>
            <a-radio value="accompany">随访</a-radio>
          </a-radio-group>
        </div>
      </div>
    </a-modal>
    <!-- 弹窗 e -->
  </div>
</template>


<script>
import utils from "../utils/utils.js";
export default {
  name: "question",
  data() {
    return {
      isShowModel: false, //控制弹窗显隐
      isBounceNode: true, //判断选项关联、跳题逻辑

      connectList: [], //搜索后的节点列表
      chooseNode: "",
      nodeInput: "", //与选项关联输入框绑定
      willUpdateQs: "", //即将更新的选项
      willUpdateIdx: "",//即将更新选项的下标
      questionList: "", //储存跳题逻辑所需要的所有问题
      // 新增
      qType: "1",
      userInfo: JSON.parse(localStorage.getItem("userInfo")), //获取token
      questionDetail: {
        answer_list: [],
        id: "",
        message_type: "",
        profile_type: "",
        question: "",
        routine_id: "",
        type: 1,
        type_display: "单选",
      },
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      modelTitle: "",
      visible: false,
      connectName: "",
      timer: null,
      results: "",
      modelValue: "",
      to: {
        type: "",
        index: "",
      },
      associatedNodeId: "",
      connectModelTitle: "",
      answerList: "",
      nVal: "",
      oVal: "",
    };
  },
  methods: {
    /**
     *  选择题目关联类型（无关联：0/跳题逻辑：1/选项关联：2
     */
    showModel(item, type,index) {
      let answer_list = this.questionDetail.answer_list;
      if (type === 0) {
        answer_list = answer_list.map((it,idx) => {
          if (idx === index) {
            it.toType = 0;
            it.to = null;
            it.toTypeName = "无关联";
            it.to_id_name = "";
          }
          return it;
        });
        this.questionDetail.answer_list = JSON.parse(
          JSON.stringify(answer_list)
        );
        this.answer_list = JSON.parse(JSON.stringify(answer_list));
      } else if (type === 1) {
        this.isShowModel = true;
        this.isBounceNode = false;
        this.willUpdateQs = item;
        this.willUpdateIdx = index;
      } else {
        this.isShowModel = true;
        this.isBounceNode = true;
        this.willUpdateQs = item;
        this.willUpdateIdx = index;
      }
    },

    /**
     * 取消关联 —— 关闭弹窗
     */
    canceConnect() {
      this.isShowModel = false;

      this.questionDetail.answer_list = this.questionDetail.answer_list.map(
        (item,index) => {
          if (index === this.willUpdateIdx) {
            item.toTypeName =
              item.to_type == 0
                ? "无关联"
                : item.to_type == 1
                ? "跳题逻辑"
                : "选项关联";
          }
          return item;
        }
      );
      /**
       * 清空输入框内容
       * 清空选择的节点、问题
       * 清空选择的模块
       * 清空当前操作的问题
       * 清空下拉框列表
       */

      this.connectList = [];
      this.nodeInput = "";
      this.chooseNode = "";
      this.modelValue = "";
      this.willUpdateQs = "";
      this.willUpdateIdx = "";
    },

    inputFocus() {
      if (this.isBounceNode) {
        if (this.nodeInput) {
          this.connect1(this.nodeInput);
        }
      } else {
        this.connectList = JSON.parse(JSON.stringify(this.questionList));
      }
    },

    connect1(value) {
      let that = this;
      if (!this.modelValue) {
        // console.log('没有选择模块')
        return;
      }
      if (that.timer) {
        //清理定时器
        clearTimeout(that.timer);
      }

      that.timer = setTimeout(() => {
        utils
          .get(
            `/routine/v2/routine/list`,
            utils.combineFromParamForNull({
              keywords: value,
              phase: that.modelValue,
            }),
            { Authorization: "Bearer " + that.userInfo.access_token }
          )
          .then((res) => {
            if (res.code == 200) {
              that.connectList = res.data;
            } else {
              that.$message.error(res.msg);
            }
          });
      });
    },

    async connect2() {
      let that = this;
      let dataList = [];
      await utils
        .get(
          `/routine/v2/routine/questionnaire/question_list`,
          utils.combineFromParamForNull({
            id: this.$route.query.questionnaireId,
          }),
          { Authorization: "Bearer " + this.userInfo.access_token }
        )
        .then(({ code, data, msg }) => {
          if (code == 200) {
            that.questionList = data.map((it) => {
              it.title = it.question;
              return it;
            });
            dataList = JSON.parse(JSON.stringify(that.questionList));
          } else {
            that.$message.error(msg.msg);
          }
        });
      return dataList;
    },
    /**
     * 输入框变化
     */
    inputChange(value) {
      let that = this;

      if (that.isBounceNode) {
        if (!value) {
          that.connectList = [];
          // console.log("搜索框为空");
          return;
        }
        if (!(that.chooseNode && that.chooseNode.title == value)) {
          that.chooseNode = "";
        }
        this.connect1(value);
      }
    },

    /**
     * 选择关联的节点
     */
    selectNode(item) {
      this.chooseNode = item;
      this.nodeInput = item.title;
    },

    changeConnect(node) {
      let answer_list = JSON.parse(
        JSON.stringify(this.questionDetail.answer_list)
      );
      answer_list = answer_list.map((item,index) => {
        if (index === this.willUpdateIdx) {
          let obj = {
            type: this.isBounceNode ? 2 : 1,
            data: {
              id: node.id,
            },
          };
          item.to = obj; //设置选项关联需要提交的数据结构
          item.to_id_name = this.nodeInput; //小标签显示字段
          item.toTypeName = this.isBounceNode ? "选项关联" : "跳题逻辑"; //题目关联类型显示字段
          item.to_type = this.isBounceNode ? 2 : 1;
        }
        return item;
      });
      this.questionDetail.answer_list = JSON.parse(JSON.stringify(answer_list));
      this.answerList = JSON.parse(JSON.stringify(answer_list));
      this.$forceUpdate();
      this.connectList = [];
      this.nodeInput = "";
      this.chooseNode = "";
      this.willUpdateQs = "";
      this.willUpdateIdx = "";
      this.modelValue = "";
      this.isShowModel = false;
    },
    async getAllList() {
      let that = this;
      let dataList = [];
      await utils
        .get(
          `/routine/v2/routine/list`,
          utils.combineFromParamForNull({
            keywords: "",
            phase: that.modelValue,
          }),
          { Authorization: "Bearer " + that.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            dataList = res.data;
          }
        });
      return dataList;
    },

    /**
     * 选项关联——确定——选择节点关联
     */
    async setConnect() {
      if (!this.nodeInput) {
        this.isBounceNode
          ? this.$message.warning("关联节点不能为空")
          : this.$message.warning("关联问题不能为空");
        return;
      }

      if (this.isBounceNode && this.modelValue == "") {
        this.$message.warning("请选择一个流程");
        return;
      }
      let dataList, chooseNodeCopy;
      if (this.isBounceNode) {
        await this.getAllList().then((res) => {
          dataList = res;
        });
      } else {
        await this.connect2().then((res) => {
          dataList = res;
        });
      }

      let isSame = dataList.findIndex((it) => {
        if (it.title == this.nodeInput) {
          chooseNodeCopy = it;
        }
        return it.title == this.nodeInput;
      });

      if (isSame > -1) {
        this.changeConnect(chooseNodeCopy);
      } else {
        if (this.isBounceNode) {
          let node;
          await this.createdNewNode().then((res) => {
            node = res;
          });
          if (!node) {
            this.connectList = [];
            this.searchWord = "";
            this.nodeInput = "";
            this.chooseNode = "";
            this.willUpdateQs = "";
            this.willUpdateIdx = "";
            this.modelValue = "";
            this.isShowNode = false;
            return;
          }
          this.chooseNode = node;
          this.changeConnect(node);
        } else {
          let params = {
            questionnaireId: this.$route.query.questionnaireId,
            type: 1,
            question: this.nodeInput,
            answerList: [],
          };
          utils
            .postJson(
              "/routine/v2/routine/questionnaire/question/create",
              utils.combineFromParamForNull(params),
              {
                Authorization: "Bearer " + this.userInfo.access_token,
              }
            )
            .then((res) => {
              if (res.code == 200) {
                this.$message.info("创建成功");
                this.chooseNode = res.data;
                this.changeConnect(res.data);
                this.connectList = [];
                this.searchWord = "";
                this.nodeInput = "";
                this.chooseNode = "";
                this.willUpdateQs = "";
                this.willUpdateIdx = "";
                this.modelValue = "";
                this.isShowNode = false;
              } else {
                this.$message.error(res.msg);
              }
            });
        }
      }
    },

    /**
     * 选项关联——创建节点
     */
    async createdNewNode() {
      let that = this;
      let node;
      await utils
        .postJson(
          `/routine/v2/routine/create`,
          utils.combineFromParamForNull({
            parent_id: this.$route.query.routine_id, //获取评估节点id（不要和问卷id搞混哦）
            title: this.nodeInput,
            phase: this.modelValue,
          }),
          { Authorization: "Bearer " + this.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            node = res.data;
            that.$message.info("创建节点");
          } else {
            that.$message.error(res.data.msg);
          }
        });
      return node;
    },
    /**
     * 删除关联
     */
    hangdleTag(e, index) {
      this.questionDetail.answer_list[index].to_id_name = null;
      this.questionDetail.answer_list[index].toTypeName = "无关联";
      let obj = {
        type: 0,
        data: {},
      };
      this.questionDetail.answer_list[index].to = obj;
      delete this.questionDetail.answer_list[index].to_id;
      delete this.questionDetail.answer_list[index].to_type;
      delete this.questionDetail.answer_list[index].to_id_name;
      this.answerList = JSON.parse(
        JSON.stringify(this.questionDetail.answer_list)
      );
      this.$forceUpdate();
    },
    async createdNode() {
      let id = this.$route.query.questionnaireId; //获取评估节点id
      let that = this;
      let nodeId;
      await utils
        .postJson(
          `/routine/v2/routine/create`,
          utils.combineFromParamForNull({
            parent_id: id,
            title: this.nodeInput,
            phase: this.modelValue,
          }),
          { Authorization: "Bearer " + this.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            that.$message.info("创建节点");
            nodeId = res.data.id;
          } else {
            that.$message.error(res.msg);
          }
        });
      return nodeId;
    },
    addAnswer() {
      //添加选项
      let obj = {
        title: "",
        rank: "0",
        score: "",
        toTypeName: "无关联",
        to: {
          type: 0,
          data: {},
        },
      };
      this.questionDetail.answer_list.push(obj);
      this.answerList = JSON.parse(
        JSON.stringify(this.questionDetail.answer_list)
      );
    },
    del(index) {
      //删除事件
      if (index > -1) {
        this.questionDetail.answer_list.splice(index, 1);
      }
      this.answerList = JSON.parse(
        JSON.stringify(this.questionDetail.answer_list)
      );
    },
    up(index) {
      //上移事件
      if (index == 0) {
        this.$message.warning("已经是第一个了");
      }
      if (this.questionDetail.answer_list.length > 1 && index !== 0) {
        let temp1, temp2;
        this.questionDetail.answer_list.forEach((item, idx1) => {
          idx1 == index ? (temp1 = item) : "";
          idx1 == index - 1 ? (temp2 = item) : "";
        });
        this.questionDetail.answer_list = this.questionDetail.answer_list.map(
          (item, idx2) => {
            idx2 == index ? (item = temp2) : "";
            idx2 == index - 1 ? (item = temp1) : "";
            return item;
          }
        );
      }
      this.answerList = JSON.parse(
        JSON.stringify(this.questionDetail.answer_list)
      );
    },
    down(index) {
      //下移事件
      if (index == this.questionDetail.answer_list.length - 1) {
        this.$message.warning("已经是最后一个了");
      }
      if (
        this.questionDetail.answer_list.length > 1 &&
        index != this.questionDetail.answer_list.length - 1
      ) {
        let temp1, temp2;
        this.questionDetail.answer_list.forEach((item, idx1) => {
          idx1 == index ? (temp1 = item) : "";
          idx1 == index + 1 ? (temp2 = item) : "";
        });
        this.questionDetail.answer_list = this.questionDetail.answer_list.map(
          (item, idx2) => {
            idx2 == index ? (item = temp2) : "";
            idx2 == index + 1 ? (item = temp1) : "";
            return item;
          }
        );
      }
      this.answerList = JSON.parse(
        JSON.stringify(this.questionDetail.answer_list)
      );
    },
    //问题类型(单选、多选……)
    changType(value) {
      this.questionDetail.type = value - 0;
    },
    //保存
    info() {
      let questionDetail = JSON.parse(JSON.stringify(this.questionDetail));
      let answerlist = JSON.parse(
        JSON.stringify(this.questionDetail.answer_list)
      );
      if (!questionDetail.question) {
        this.$message.warn("问卷名称不可为空");
        return;
      }
      if (this.questionDetail.type == 1) {
        if (!(answerlist.length > 0)) {
          this.$message.warn("请至少设置一个问题");
          return;
        }
      } else if (this.questionDetail.type == 2) {
        if (!(answerlist.length > 1)) {
          this.$message.warn("多选题请至少设置两个问题");
          return;
        }
      }
      let that = this;
      let questionnaireId = this.$route.query.questionnaireId;
      let id = this.$route.query.questId;
      let params;
      let address;
      let answer_list = JSON.parse(
        JSON.stringify(this.questionDetail.answer_list)
      );
      answer_list = answer_list.map((item) => {
        if (!item.score) {
          item.score = 0;
        }
        item.attention_status = item.attention_status ? 1: 0;
        return item;
      });
      if (id == "created") {
        if (this.questionDetail.type == 1 || this.questionDetail.type == 2) {
          params = {
            questionnaireId: questionnaireId,
            type: this.questionDetail.type,
            question: this.questionDetail.question,
            answerList: answer_list,
          };
        }
        if (this.questionDetail.type == 3 || this.questionDetail.type == 4) {
          params = {
            questionnaireId: questionnaireId,
            type: this.questionDetail.type,
            question: this.questionDetail.question,
          };
        }
        address = "/routine/v2/routine/questionnaire/question/create";
      } else {
        if (this.questionDetail.type == 1 || this.questionDetail.type == 2) {
          params = {
            id: id,
            questionnaireId: questionnaireId,
            type: this.questionDetail.type,
            question: this.questionDetail.question,
            answerList: answer_list,
          };
        }
        if (this.questionDetail.type != 1 && this.questionDetail.type != 2) {
          params = {
            id: id,
            questionnaireId: questionnaireId,
            type: this.questionDetail.type,
            question: this.questionDetail.question,
          };
        }
        address = "/routine/v2/routine/questionnaire/question/update";
      }
      utils
        .postJson(address, utils.combineFromParamForNull(params), {
          Authorization: "Bearer " + this.userInfo.access_token,
        })
        .then((res) => {
          if (res.code == 200) {
            if (id == "created") {
              that.$message.info("创建成功");
              that.$router.go(-1);
            } else {
              that.$message.info("操作成功");
            }
          } else {
            that.$message.error(res.msg);
          }
        });
    },
    getList() {
      let id = this.$route.query.questId;
      let that = this;
      utils
        .get(
          `/routine/v2/routine/questionnaire/question/detail`,
          utils.combineFromParamForNull({
            id: id,
          }),
          { Authorization: "Bearer " + this.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            this.questionDetail = res.data;

            this.questionDetail.answer_list =
              this.questionDetail.answer_list.map((item) => {
                if (item.to_type == 0) {
                  item.toTypeName = "无关联";
                } else if (item.to_type == 1) {
                  item.toTypeName = "跳题逻辑";
                } else if (item.to_type == 2) {
                  item.toTypeName = "选项关联";
                }
                item.attention_status = item.attention_status === 1;
                return item;
              });
            this.answerList = JSON.parse(
              JSON.stringify(this.questionDetail.answer_list)
            );
          } else {
            that.$message.error(res.msg);
          }
        });
    },
  },
  created() {
    let str = this.$route.query.questId;
    if (str != "created") {
      this.getList();
      this.connect2();
    }
    this.questionDetail.questionnaireId = this.$route.query.routine_id; //获取问卷id
  },
  computed: {
    nowRouter: function () {
      return this.$route.path;
    },
  },
};
</script>

<style scoped>
/deep/.ant-select-auto-complete {
  width: 60% !important;
}
.a-tip {
  text-decoration: underline;
  font-size: 12px;
  margin-left: 10px;
}
</style>

