import axios from 'axios'

const service = axios.create({
    baseURL: '/api',
    headers: {
        'secret': '2cdf9b9c51298ae17c22bc84d7a90005',
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [
      function(data) {
          let ret = '';
          for (let it in data) {
              ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
          }
          ret = ret.substring(0, ret.lastIndexOf('&'));
          return ret;
      }
    ],
    timeout:1000,
})

service.interceptors.request.use((config)=>{
  JSON.parse(localStorage.getItem('userInfo'))
    let token = JSON.parse(localStorage.getItem('userInfo')).access_token
    config.data.uToken = token
    return config
})
 
service.interceptors.response.use(response => {
    return response.data
  }, error => {
    return Promise.resolve(error.response)
  });
export default service