	<template>
  <div>
    <a-space direction="vertical">
      <a-button
        shape="circle"
        icon="plus"
        @click="
          () => {
            flow = true;
            newTitle = '';
          }
        "
      />
      <a-modal
        title="增加流程"
        :visible="flow"
        @ok="handleOk"
        @cancel="flow = false"
        cancelText="取消"
        okText="确定"
      >
        <div>
          <span class="tantitle">流程名称</span>
          <a-input placeholder="流程名称" v-model="newTitle" />
        </div>
        <div>
          <span class="tantitle">创建者</span>
          <a-input :value="userInfo.name" disabled />
        </div>
      </a-modal>
      <!-- 添加流程和弹窗 end -->

      <!-- 流程内容start -->
      <a-space direction="vertical">
        <div class="group" v-for="(item, index) in illData" :key="index">
          <!-- 路由跳转 -->
          <span
            @click="push(item)"
            style="display: inline-block; width: 200px; cursor: pointer"
          >
            {{ item.title }}
          </span>
          <!-- <router-link to="/point">
          
        </router-link> -->
          <!-- 修改按钮  edit(item)保存当前item的title -->
          <a-space>
            <a-button
              type="primary"
              icon="eye"
              shape="circle"
              @click="push(item)"
            ></a-button>

            <a-button
              type="primary"
              shape="circle"
              icon="edit"
              @click="edit(item)"
            ></a-button>

            <!-- 删除按钮 -->
            <!-- <a-button type="primary" icon="minus" @click="remove(item)"></a-button> -->
            <a-popconfirm
              title="您确定要删除该流程吗?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="remove(item)"
            >
              <a-button type="danger" icon="delete" shape="circle"></a-button>
            </a-popconfirm>
          </a-space>
        </div>
      </a-space>
    </a-space>
    <a-modal
      title="修改流程名称"
      :visible="visible"
      okText="确定"
      cancelText="取消"
      @ok="editOk(modalItem)"
      @cancel="editCancel"
    >
      <a-input
        onfocus="this.select()"
        placeholder="请输入流程名称"
        v-model="newTitle"
      />
    </a-modal>
  </div>
</template>

	<script>
import utils from "../utils/utils.js";
import Point from "./point.vue";
export default {
  name: "home",
  components: {
    Point,
  },
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      newTitle: "",
      visible: false,
      flow: false,
      illData: "",
      modalItem: "",
    };
  },

  created() {
    this.getList();
  },
  methods: {
    getList() {
      let that = this;
      utils
        .get(
          `/routine/v2/routine/list`,
          utils.combineFromParamForNull({
            parent_id: 0,
          }),
          { Authorization: "Bearer " + that.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            that.illData = res.data;
          } else {
            that.$message.error(res.data.msg);
          }
        });
    },

    push(item) {
      let that = this;
      let node = {
        id: item.id,
        title: item.title,
        phase: item.phase,
        type_name: "(服务流程)",
      };
      that.$store.state.nodeList.push(node);
      let arr = that.count;
      let obj = {
        path: that.$route.path,
        title: item.title + "(服务流程)",
      };
      arr.push(obj);
      that.$store.commit("clickReset", arr);
      that.$router.push("/point");
      that.changeId(item.id);
    },
    changeId(id) {
      this.$store.commit("changeId", id);
    },
    handleOk() {
      let that = this;
      if(!this.newTitle){
        that.$message.warn('流程名称不能为空');
        return
      }
      let newTitle = this.newTitle.replace(/\s*/g,"")
      if(this.illData.findIndex(it => it.title == newTitle) > -1){
        that.$message.error('已有同名流程');
        return
      }
      utils
        .postJson(
          `/routine/v2/routine/create`,
          utils.combineFromParamForNull({
            parent_id: 0,
            title: that.newTitle,
          }),
          { Authorization: "Bearer " + that.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("创建成功");
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      that.flow = false;
    },
    //修改流程标题
    edit(item) {
      this.visible = true;
      this.modalItem = item;
      this.newTitle = item.title;
    },
    editOk(e) {
      let that = this;
      if(!this.newTitle){
        that.$message.warn('流程名称不能为空');
        return
      }
      let newTitle = this.newTitle.replace(/\s*/g,"")
      if(this.illData.findIndex(it => it.title == newTitle) > -1){
        that.$message.error('已有同名流程');
        return
      }
      
      that.visible = false;
      //遍历数组，修改title
      utils
        .postJson(
          `/routine/v2/routine/update`,
          utils.combineFromParamForNull({
            id: e.id,
            title: that.newTitle,
          }),
          { Authorization: "Bearer " + that.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("已修改");
            this.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
    },
    editCancel(e) {
      this.visible = false;
    },
    change(data) {
      data.map((item) => {
        if (item.title == this.nowTitle) {
          item.title = this.newTitle;
          return item;
        }
      });
    },
    remove(nowitem) {
      let that = this;
      that.illData = that.illData.filter((item) => {
        return item.id != nowitem.id;
      });
      utils
        .postJson(
          `/routine/v2/routine/delete`,
          utils.combineFromParamForNull({
            id: nowitem.id,
          }),
          { Authorization: "Bearer " + that.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("已删除");
          } else {
            that.$message.error(res.data.msg);
          }
        });
    },
  },
  computed: {
    count() {
      return this.$store.state.routes;
    },
  },
};
</script>

	<style scoped="scoped">
a {
  color: #000000;
  width: 200px;
  display: inline-block;
}
.ant-input {
  width: 50%;
}
.tantitle {
  font-weight: bold;
  display: inline-block;
  width: 20%;
  text-align: center;
  margin-right: 5px;
}
</style>
