<template>
  <div>
    <div>
      <a-button class="editable-add-btn" @click="handleAdd">add</a-button>
      <a-table bordered :data-source="dataSource" :columns="columns">
        <template slot="associatedModule" slot-scope="text, record">
          <span @click="connecTo(record.key)">{{
            record.associatedModule
          }}</span>
        </template>

        <template slot="operation" slot-scope="text, record">
          <a-space size="10px">
            <a-button
              style="margin-right: 10px"
              @click="updata(record.key)"
              type="primary"
              shape="circle"
              icon="edit"
            ></a-button>
            <a-popconfirm
              v-if="dataSource.length"
              title="Sure to delete?"
              @confirm="() => onDelete(record.key)"
            >
              <a-button type="danger" shape="circle" icon="delete"></a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </div>

    <!-- 弹窗 -->

    <div class="box" v-if="rouleBoxShow">
      <a-card :title="rolueTypeName" style="width: 900px">
        <a-space direction="vertical" size="large">
          <a-space align="start">
            <span>规则名称</span>
            <a-input v-model="ruleName"></a-input>
          </a-space>
          
          <a-space align="start">
            <span>表达式</span>
            <a-space direction="vertical">
              <!-- <a-space direction="vertical" v-for="(item,index) in expressionList" :key=index> -->
              <a-space
                align="center"
                v-for="(item, index) in expressionList"
                :key="index"
              >
                <a-input style="width: 300px" value="问卷总分"></a-input>
                <a-select
                  :default-value="item.operator"
                  v-model="item.operator"
                  style="width: 120px"
                  @change="
                    (value) => {
                      changeOperator(value, index);
                    }
                  "
                >
                  <a-select-option value=">"> > </a-select-option>
                  <a-select-option value=">="> >= </a-select-option>
                  <a-select-option value="="> = </a-select-option>
                  <a-select-option value="<"> < </a-select-option>
                  <a-select-option value="<="> <= </a-select-option>
                </a-select>
                <a-input
                  style="width: 100px"
                  v-model="item.operand_list[1].value"
                ></a-input
                >分
                <a-button icon="plus" @click="changeExpression(1)" />
                <a-button icon="minus" @click="changeExpression(2,index)" />
              </a-space>
            </a-space>
          </a-space>
          <a-space align="start">
            <span>表达式关系</span>
            <a-select
              style="width: 120px"
              :default-value="logic_operator"
              v-model="logic_operator"
              @change="changeOperatorTotal"
            >
              <a-select-option value="and">and</a-select-option>
              <a-select-option value="or">or</a-select-option>
              <a-select-option value="not">not</a-select-option>
            </a-select>
          </a-space>
          <a-space align="start">
            <span>关联模块</span>
            <span style="display: inline-block">
              <a-space direction="vertical">
                <a-auto-complete
            class="global-search"
            size="large"
            style="width: 100%"
            placeholder="请选择模块并输入关键字以搜索流程"
            option-label-prop="title"
            allowClear
            v-model="nodeInput"
            @search="inputChange"
            @focus="inputFocus"
          >
            <template slot="dataSource">
              <a-select-option
                @click="selectNode(item)"
                v-for="item in connectList"
                :key="item.title"
                :title="item.title"
              >
                <span className="global-search-item-count">{{
                  item.title
                }}</span>
              </a-select-option>
            </template>
          </a-auto-complete>
                <a-radio-group v-model="modelValue">
                  <a-radio value="evaluate">评估</a-radio>
                  <a-radio value="diagnose">诊断</a-radio>
                  <a-radio value="intervention">干预</a-radio>
                  <a-radio value="accompany">随访</a-radio>
                </a-radio-group>
              </a-space>
            </span>
          </a-space>

          
          <a-row type="flex" justify="center">
            <a-col :span="4">
              <a-button @click="quit">取消</a-button>
            </a-col>
            <a-col :span="4">
              <a-button type="primary" @click="handok">确定</a-button>
            </a-col>
          </a-row>
        </a-space>
      </a-card>
    </div>
  </div>
</template>
<script>
import utils from "../utils/utils.js";
const EditableCell = {
  template: `
      <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
          <a-input :value="value" @change="handleChange" @pressEnter="check" /><a-icon
            type="check"
            class="editable-cell-icon-check"
            @click="check"
          />
        </div>
        <div v-else class="editable-cell-text-wrapper">
          {{ value || ' ' }}
          <a-icon type="edit" class="editable-cell-icon" @click="edit" />
        </div>
      </div>
    `,
  props: {
    text: String,
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    handleChange(e) {
      const value = e.tar.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};
export default {
  name: "connect",
  components: {
    EditableCell,
  },
  data() {
    return {
      timer: null,
      rolueTypeName: "",
      rolueType: "",
      modelValue: "", //关联模块
      dataSource: [
        {
          key: "0",
          name: "规则1",
          area: "问卷总分<3",
          associatedModule: "评估2",
        },
      ],
      count: 2,
      columns: [
        {
          title: "规则",
          dataIndex: "name",
          width: "30%",
          // scopedSlots: { customRender: 'name' },
        },
        {
          title: "分值区间",
          dataIndex: "area",
        },
        {
          title: "关联模块",
          dataIndex: "associatedModule",
          scopedSlots: { customRender: "associatedModule" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      path: "", //路由暂存区
      userInfo: JSON.parse(localStorage.getItem("userInfo")), //获取token
      // detailPath:'',
      rouleBoxShow: false, //控制规则区域是否出现
      newTitle: "",
      results: "",
      //规则编辑中的数据start
      ruleName: "", //规则名称
      expressionList: [
        {
          operator: "",
          operand_list: [
            {
              type: "total_score",
              value: null,
            },
            {
              type: "const_score",
              value: "",
            },
          ],
        },
      ],
      connectModelId: "",
      connectModelTitle: "",
      logic_operator: "",
      ruleId: "", //规则id
      nodeInput: "",
      connectList: [],
      nowConnextItem:null,
    };
  },
  methods: {
    inputChange(value) {
      if (!value) {
        this.connectList = [];
        // console.log("搜索框为空");
        return;
      }
      this.connect1(value);
    },
    connect1(value) {
      let that = this;
      if (!this.modelValue) {
        // console.log("没有选择模块");
        return;
      }
      if (that.timer) {
        //清理定时器
        clearTimeout(that.timer);
      }

      that.timer = setTimeout(() => {
        utils
          .get(
            `/routine/v2/routine/list`,
            utils.combineFromParamForNull({
              keywords: value,
              phase: that.modelValue,
            }),
            { Authorization: "Bearer " + that.userInfo.access_token }
          )
          .then((res) => {
            if (res.code == 200) {
              that.connectList = res.data;
            } else {
              that.$message.error(res.msg);
            }
          });
      });
    },
    inputFocus() {
      if(this.nodeInput){
        this.connect1(this.nodeInput);
      }
    },
    selectNode(item) {
      this.nowConnextItem = item;
    },
    changeExpression(type,index) {
      if(type == 1){
      const obj = {
        operator: "",
        operand_list: [
          {
            type: "total_score",
            value: null,
          },
          {
            type: "const_score",
            value: "",
          },
        ],
      };
      this.expressionList.push(obj);
      }else{
        this.expressionList.splice(index,1)
      }
      
    },
    ruleJudge(){
      if(!this.ruleName){
        this.$message.warn("规则名称不能为空");
        return false
      }
      if(!this.expressionList.length > 0){
        this.$message.warn("至少填写一条表达式");
        return false
      }else{
        let isPass = true;
        console.log(this.expressionList,'46545')
        this.expressionList.forEach(item => {
          if(!item.operator){
            isPass = false;
          }
          item.operand_list.forEach(it => {
            if(it.type == "const_score"){
              if(!String(it.value)){
                isPass = false;
              }
            }
          })
        })
        if(!isPass){
          this.$message.warn("请将表达式补充完整或将不完整的表达式删除");
          return false
        }
      }
      if(!this.nodeInput){
        this.$message.warn("关联模块名称不能为空");
        return false
      }
      if(!this.modelValue){
        this.$message.warn("请选择关联模块");
        return false
      }
      this.rouleBoxShow = false;
      return true
    },
    createdRule() {
      if(!this.ruleJudge()){
        return
      }
      let that = this;
      let id = this.$route.query.routine_id; //获取问卷ID
      let routine_to = {
        parentId: id,
        title: this.nodeInput,
        phase: this.modelValue,
      }
      let sameIndex = this.connectList.findIndex(it => it.title == this.nodeInput)
      if(sameIndex > -1){
        routine_to.id = this.nowConnextItem.id
      }
      utils
        .postJson(
          `/routine/v2/routine/evaluate/rule/create`,
          utils.combineFromParamForNull({
            routine_id: id,
            rule: {
              logic_operator: this.logic_operator || "and",
              expression_list: this.expressionList,
            },
            name: this.ruleName,
            routine_to: routine_to,
          }),
          { Authorization: "Bearer " + this.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            this.nodeInput = "";
            this.modelValue = ""; 
            this.getList();
            that.$message.success("创建成功");
          } else {
            that.$message.error(res.data.msg);
          }
        });
      this.nodeInput = "";
    },
    updateRule() {
      if(!this.ruleJudge()){
        return
      }
      let that = this;
      let id = this.$route.query.routine_id; //获取问卷ID
      let routine_to = {
        parentId: id,
        title: this.nodeInput,
        phase: this.modelValue,
      }
      let sameIndex = this.connectList.findIndex(it => it.title == this.nodeInput)
      if(sameIndex > -1){
        routine_to.id = this.nowConnextItem.id
      }
      utils
        .postJson(
          `/routine/v2/routine/evaluate/rule/update`,
          utils.combineFromParamForNull({
            id: this.ruleId,
            rule: {
              logic_operator: this.logic_operator,
              expression_list: this.expressionList,
            },
            name: this.ruleName,
            routine_to: routine_to,
          }),
          { Authorization: "Bearer " + this.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            this.nodeInput = "";
            this.modelValue = ""; 
            this.getList();
            that.$message.success("修改成功");
          } else {
            that.$message.error(res.data.msg);
          }
        });
      this.nodeInput = "";
    },
    handok() {
      //确认
      if (this.rolueType == "created") {
        this.createdRule();
      }
      if (this.rolueType == "update") {
        this.updateRule();
      }
    },
    getQuestionId() {
      //从路由中获取问卷id
      let arr = this.nowRouter.split("&");
      return arr[1];
    },
    getList() {
      let that = this;
      let id = this.$route.query.routine_id;
      utils
        .get(
          `/routine/v2/routine/evaluate/rule/list`,
          utils.combineFromParamForNull({
            routineId: id,
          }),
          { Authorization: "Bearer " + this.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            res.data.map((item) => {
              switch (item.to_id_phase) {
                case "diagnose":
                  item.to_type = "诊断";
                  break;
                case "intervention":
                  item.to_type = "干预";
                  break;
                case "evaluate":
                  item.to_type = "评估";
                  break;
                case "accompany":
                  item.to_type = "随访";
                  break;
              }
            });
            this.dataSource = res.data;
            this.dataSource = this.dataSource.map((item) => {
              item.area = item.description;
              item.associatedModule = item.to_id_name;
              item.key = item.id;
              return item;
            });
          } else {
            that.$message.error(res.msg);
          }
        });
    },
    filterValue(arr, i, operator) {
      let text;
      if (operator == "and") {
        text = "并且";
      } else if (operator == "or") {
        text = "或则";
      } else{
        text = "否";
      }
      if (i >= arr.length) {
        return 1;
      } else {
        if (arr.length - 1 == i) {
          return (
            "问卷总分" +
            arr[i].operator +
            arr[i].operand_list[1].value +
            this.filterValue(arr, i + 1) +
            ""
          );
        } else {
          return (
            "问卷总分" +
            arr[i].operator +
            arr[i].operand_list[1].value +
            "  " +
            text +
            "  " +
            this.filterValue(arr, i + 1) +
            ""
          );
        }
      }
    },
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource];
      const target = dataSource.find((item) => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
      }
    },
    onDelete(key) {
      let that = this;
      utils
        .postJson(
          `/routine/v2/routine/evaluate/rule/delete`,
          utils.combineFromParamForNull({
            id: key,
          }),
          { Authorization: "Bearer " + this.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            this.rouleBoxShow = false;
          } else {
            that.$message.error(res.msg);
          }
        });
      // this.getList()
      //删除页面中的dom
      const dataSource = [...this.dataSource];
      this.dataSource = dataSource.filter((item) => item.key !== key);
    },
    setEmpty() {
      this.expressionList = [];
      const obj = {
        operator: "",
        operand_list: [
          {
            type: "total_score",
            value: null,
          },
          {
            type: "const_score",
            value: "",
          },
        ],
      };
      this.expressionList.push(obj);
      this.ruleName = "";
      this.newTitle = "";
      this.modelValue = "";
    },
    handleAdd() {
      this.rouleBoxShow = true;
      this.rolueTypeName = "创建规则";
      this.rolueType = "created";
      this.setEmpty();
    },
    changeOperatorTotal(value) {
      this.logic_operator = value;
    },
    changeOperator(value, index) {
      //选择逻辑运算符后赋值给表达式列表[index]的operator
      this.expressionList[index].operator = value;
      // console.log(this.expressionList)
    },
    updata(key) {
      this.rouleBoxShow = true;
      this.rolueTypeName = "修改规则";
      this.rolueType = "update";
      this.results = "";
      this.dataSource.forEach((item) => {
        if (item.id == key) {
          this.ruleName = item.name; //规则名称
          this.nodeInput = item.to_id_name; //关联模块名称
          this.modelValue = item.to_id_phase; //关联模块的值(单选)
          this.logic_operator = item.rule.logic_operator; //表达式关系
          this.ruleId = item.id; //当前规则的id
          this.expressionList = item.rule.expression_list; //当前规则的表达式列表
        }
      });
      // const obj = {
      //   operator: "",
      //   operand_list: [
      //     {
      //       type: "total_score",
      //       value: null,
      //     },
      //     {
      //       type: "const_score",
      //       value: "",
      //     },
      //   ],
      // };
    },
    connecTo(key) {
      let connectModel = this.dataSource.filter((item) => item.key == key)[0];
      switch (connectModel.to_id_phase) {
        case "diagnose":
          connectModel.toPath = "/diagnose";
          break;
        case "intervention":
          connectModel.toPath = "/intervene";
          break;
        case "evaluate":
          connectModel.toPath = "/assessment";
          break;
        case "accompany":
          connectModel.toPath = "/visit";
          break;
      }
      this.$router.push(connectModel.toPath);
    },
    quit() {
      this.rouleBoxShow = false;
      this.rolueTypeName = "";
      const obj = {
        operator: "",
        operand_list: [
          {
            type: "total_score",
            value: null,
          },
          {
            type: "const_score",
            value: "",
          },
        ],
      };
      this.expressionList = [];
      this.expressionList.push(obj);
      this.ruleName = "";
      this.newTitle = "";
      this.modelValue = "";
    },
  },

  created() {
    this.getList();
  },
  computed: {
    parent_id: function () {
      const id = this.$route.path.replace(/[^0-9]+/g, "");
      return id;
    },
    nowRouter: function () {
      return this.$route.path;
    },
  },
  watch: {
    newTitle(nVal) {
      if (this.connectModelId && this.connectModelTitle != this.newTitle) {
        this.connectModelId = null;
      }
    },
  },
};
</script>
<style scoped>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}

/deep/.ant-modal {
  width: 900px !important;
}
.box {
  margin-top: 50px;
}
</style>
