import axios from 'axios';
// import { config } from 'vue/types/umd';
// import env from "./env.js"

const service = axios.create({
    baseURL: '/api',
    headers: { 'Content-Type': 'application/x-www-from-urlencoded' },
    settimeout: 2000,
})
// 添加请求拦截
service.interceptors.request.use(
    config => {
        // 在发送请求前都做些什么
        // 配置请求时参数
        // config.headers['deviceType'] = 'H5';
        // console.log('请求的数据', config);
        return config;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject("出错", error);
    }
);

// 添加响应拦截器
service.interceptors.response.use(
    response => {
        // 对相应数据做些什么
        // console.log("返回的数据", response);
        return response;
    },
    error => {
        // 对响应错误做点什么
        return Promise.reject(error);
    }
);
export default service;

