<template>
	<div>
		<Edit :illData="illData" @getList="getList"></Edit>
	</div>
</template>

<script>
	import Bus from '../API/bus.js';
	import utils from "../utils/utils.js";
	import Edit from '../components/edit.vue'
	export default{
		name:"diagnose",
		components:{
			Edit
		},
		data(){
			return{
				userInfo:JSON.parse(localStorage.getItem('userInfo')),
				illData:'',
			}
		},
		methods:{
			getList(){				
				let that = this;				
				let nodeList = JSON.parse(JSON.stringify(this.$store.state.nodeList));
				let data = {
					phase:'diagnose'
				}
				if(nodeList.length > 0){
					let node = nodeList.pop();
					data.parentId = node.id;
					
				}
				utils.get(
					`/routine/v2/routine/list`,
					utils.combineFromParamForNull(data),
					{ Authorization: "Bearer " + this.userInfo.access_token }
					)
					.then((res) => {
					if (res.code == 200) {
						this.illData=res.data
					} else {
						that.$message.error(res.msg);
					}
					})
			},
		},
		created(){
			this.getList();
			Bus.$off('diagnose');
		},
		mounted () {
			Bus.$on('diagnose',data => {this.getList()});
		},
		watch:{
			
		}
	}
</script>

<style scoped="scoped">
	a{
		color: #000000;
		width: 200px;
		display: inline-block;
	}
</style>
