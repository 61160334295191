<template>
  <div>
    <div class="detail">
      <div class="name">
        <a-button class="btn">名称1</a-button>
        <a-input class="input" placeholder="Basic usage" />
      </div>
      <div class="content">
        <a-button class="btn">内容</a-button>
        <a-textarea placeholder="Basic usage" :rows="4" />
      </div>
      <div class="about">
        <a-button class="btn">参考素材</a-button>
        <a-input class="input" placeholder="Basic usage" />
      </div>
      <div class="radioGroup">
        <a-button class="btn">关联</a-button>
        <a-input class="input" v-model="modelName" placeholder="Basic usage" />
      </div>
      <div class="radio">
        <a-radio-group v-model="value" @change="onChange">
          <a-radio :value="1"> 评估 </a-radio>
          <a-radio :value="2"> 诊断 </a-radio>
          <a-radio :value="3"> 干预 </a-radio>
          <a-radio :value="4"> 随访 </a-radio>
        </a-radio-group>
      </div>
      <a-button class="submit" type="primary" @click="toPath()">
        提交
      </a-button>
    </div>
  </div>
</template>

<script>
import utils from "../utils/utils.js";
export default {
  name: "illnessDetail",
  data() {
    return {
      modelName: "",
      value: 1,
      path: "",
      phase: "",
      detailPath: "",
      userInfo: JSON.parse(localStorage.getItem("userInfo")), //获取token
    };
  },
  methods: {
    onChange(e) {
      //  console.log('56565', e.target.value);
    },
    toPath() {
      let that = this;
      switch (that.value) {
        case 1:
          that.model = "评估";
          that.path = "/assessment";
          that.phase = "evaluate";
          that.detailPath = "/preview/";
          break;
        case 2:
          that.model = "诊断";
          that.path = "/diagnose";
          that.phase = "diagnose";
          that.detailPath = "/illnessDetail/";
          break;
        case 3:
          that.model = "干预";
          that.path = "/intervene";
          that.phase = "intervention";
          that.detailPath = "/interContent/";
          break;
        case 4:
          that.model = "随访";
          that.path = "/visit";
          that.phase = "accompany";
          that.detailPath = "/preview/";
          break;
      }
      utils
        .postJson(
          `/routine/v2/routine/create`,
          utils.combineFromParamForNull({
            parent_id: that.parent_id,
            title: that.modelName,
            phase: that.phase,
          }),
          { Authorization: "Bearer " + that.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            that.$router.push(that.path);
          } else {
            that.$message.error(res.msg);
          }
        });
    },
  },
  computed: {
    parent_id: function () {
      let that = this;
      return that.$route.query.id;
    },
  },
};
</script>

<style scoped="scoped">
.detail {
  width: 80%;
  min-width: 600px;
  margin: 0 auto;
  padding: 40px 0 0 0;
}
.name,
.content,
.about {
  width: 100%;
}
.name,
.content,
.about,
.radioGroup {
  margin-bottom: 40px;
}
.radio {
  margin-left: 20%;
  padding-left: 10px;
  margin-bottom: 40px;
}
.btn {
  width: 20%;
  min-width: 60px;
  margin-right: 10px;
}
.ant-input {
  width: 70%;
}
.submit {
  width: 60px;
  margin: 0 auto;
  display: block;
}
</style>
