<template>
  <div>
    <div>
      <!-- <h1 v-text="evaluateTitle"></h1> -->
      <h1>评估节点:{{ evaluateTitle }}</h1>
      <!-- <a-space>
        <a-input
          v-model="newTitle"
          type="text"
          placeholder="请输入关键词"
          @input="handleQuery"
        />
        <a-button icon="check" @click="sure" />
      </a-space>
      <span>
        <li
          v-for="(item, index) in results"
          :key="index"
          v-html="item.name"
          @click="getText(item.name, item.id)"
        ></li>
      </span> -->
    </div>
    <div class="box">
      <a-button @click="add">添加问题</a-button>
      <a-empty v-if="list.length < 1" />
      <div v-else>
        <table class="table table-striped">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Id</th>
              <th scope="col">创建者</th>
              <th scope="col">问题</th>
              <th scope="col">创建时间</th>
              <th scope="col">更新时间</th>
              <th scope="col">题型</th>
              <th scope="col">操作</th>
            </tr>
          </thead>
          <draggable
            v-model="list"
            tag="tbody"
            :animation="100"
            @start="dragging = true"
            @end="dragging = false"
          >
            <tr
              class="line"
              style="height: 50px; line-height: 50px"
              v-for="(item, index) in list"
              :key="index"
            >
              <td scope="row">{{ item.id }}</td>
              <td>{{ item.created_by }}</td>
              <td>{{ item.question }}</td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.updated_at }}</td>
              <td>{{ item.type_display }}</td>
              <td>
                <a-space>
                  <a-button
                    @click="edit(item.id)"
                    type="primary"
                    shape="circle"
                    icon="edit"
                  ></a-button>
                  <a-popconfirm
                    title="您确定要删除该问卷吗?"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="() => removeAt(index, item.id)"
                  >
                    <a-button
                      type="danger"
                      shape="circle"
                      icon="delete"
                    ></a-button>
                  </a-popconfirm>
                </a-space>
              </td>
            </tr>
          </draggable>
        </table>
        <a-button type="primary" @click="toConnect">提交问卷</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../utils/utils.js";
import draggable from "vuedraggable";
export default {
  name: "preview",
  components: {
    draggable,
  },
  data() {
    return {
      nowName: "",
      list: [],
      dragging: false,
      userInfo: JSON.parse(localStorage.getItem("userInfo")), //获取token
      evaluateTitle: "",
      newTitle: "",
      timer: null,
      results: "",
      resultsList: "",
    };
  },
  methods: {
    sure() {
      let that = this;
      utils
        .postJson(
          `/routine/v2/routine/update`,
          utils.combineFromParamForNull({
            id: that.$route.query.routine_id,
            title: that.newTitle,
            phase: "evaluate",
            data: {
              quesitonnaire_id: that.questionnaire_id,
            },
          }),
          { Authorization: "Bearer " + that.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            // console.log(res.data)
          } else {
            that.$message.error(res.msg);
          }
        });
    },
    add() {
      let that = this;
      let routine_id = that.$route.query.routine_id;
      // this.$router.push('/question&'+questionId+'&'+this.questionnaireId+'&'+'created')
      this.$router.push(
        "/question?routine_id=" +
          routine_id +
          "&questionnaireId=" +
          this.questionnaireId +
          "&questId=created"
      );
    },
    removeAt(idx, id) {
      let that = this;
      utils
        .postJson(
          `/routine/v2/routine/questionnaire/question/delete`,
          utils.combineFromParamForNull({
            id: id,
          }),
          { Authorization: "Bearer " + this.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            that.$message.info("操作成功");
            this.list.splice(idx, 1);
          } else {
            that.$message.error(res.msg);
          }
        });
    },
    edit(id) {
      let that = this;
      let routine_id = that.$route.query.routine_id;
      // this.$router.push('/question&'+questionId+'&'+this.questionnaireId+'&'+id)
      that.$router.push(
        "/question?routine_id=" +
          routine_id +
          "&questionnaireId=" +
          that.questionnaireId +
          "&questId=" +
          id
      );
    },
    toConnect() {
      let that = this;
      let routine_id = that.$route.query.routine_id;
      // const id=this.nowRouter.replace(/[^0-9]+/g,'')
      that.$router.push("/connect/?routine_id=" + routine_id);
    },
    // getQuestionId(){//从路由中获取问卷id
    // 	let arr =this.nowRouter.split("&")
    // 	return arr
    // },
    getList() {
      //获取问题列表数据
      let that = this;
      let id = that.$route.query.routine_id;
      utils
        .get(
          `/routine/v2/routine/detail`,
          utils.combineFromParamForNull({
            id: id,
          }),
          { Authorization: "Bearer " + that.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            let data = res.data;
            res.data.questionnaire
              ? ((that.list = data.questionnaire.question_list),
                (that.questionnaireId = data.questionnaire.id))
              : "";
          } else {
            that.$message.error(res.msg);
          }
        });
    },
    getTitle() {
      let that = this;
      utils
        .get(
          `/routine/v2/routine/list`,
          utils.combineFromParamForNull({
            phase: "evaluate",
          }),
          { Authorization: "Bearer " + that.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            let data = res.data;
            let a = that.$route.query.routine_id;
            data.forEach((item) => {
              a == item.id ? (that.evaluateTitle = item.title) : "";
            });
          } else {
            that.$message.error(res.msg);
          }
        });
    },
    // handleChangeName() {
    // 	let = that = this;
    //   if (this.newTitle == "") {
    //     this.results = "";
    //     return;
    //   }
    //   utils
    //     .get(
    //       `/routine/v2/routine/questionnaire/list`,
    //       utils.combineFromParamForNull({ keywords: this.newTitle }),
    //       { Authorization: "Bearer " + this.userInfo.access_token }
    //     )
    //     .then((res) => {
    //       if (res.code == 200) {
    //         this.changeColor(res.data);
    //       } else {
    //         that.$message.error(res.msg);
    //       }
    //     });
    // },
    // clearTimer() {
    //   if (this.timer) {
    //     //清理定时器
    //     clearTimeout(this.timer);
    //   }
    // },
    // handleQuery(event) {
    //   //设置定时器(防抖)
    //   this.clearTimer();
    //   this.timer = setTimeout(() => {
    //     this.handleChangeName();
    //   }, 300);
    // },
    // changeColor(resultsList) {
    //   //输入文字高亮
    //   resultsList.map((item, index) => {
    //     // console.log('item', item)
    //     if (this.newTitle && this.newTitle.length > 0) {
    //       // 匹配关键字正则
    //       let replaceReg = new RegExp(this.newTitle, "g");
    //       // 高亮替换v-html值
    //       let replaceString =
    //         '<span class="search-text">' + this.newTitle + "</span>";
    //       resultsList[index].name = item.name.replace(
    //         replaceReg,
    //         replaceString
    //       );
    //     }
    //   });
    //   this.results = [];
    //   this.results = resultsList;
    // },
    // 把带有html 的文本变成text文本
    // repalceHtmlToText(str) {
    //   str = str.replace(/<\/?.+?>/g, "");
    //   str = str.replace(/&nbsp;/g, "");
    //   return str;
    // },
    // setQuesitonnaireID(id) {
    //   this.questionnaire_id = id;
    // },
    // // 得到转化后的文本赋值给 newTitle
    // getText(name, id) {
    //   this.setQuesitonnaireID(id);
    //   this.newTitle = this.repalceHtmlToText(name);
    //   // this.$emit("getName",name)
    //   // console.log(id)
    // },
  },
  created() {
    this.getList();
    this.getTitle();
  },
  computed: {
    nowRouter: function () {
      return this.$route.path;
    },
  },
};
</script>
<style scoped>
.box {
  width: 100%;
}
.table {
  width: 100% !important;

  color: #212529;
}
.thead-dark {
  width: 100%;
  height: 54px;
}

.table-striped tbody tr {
  border-bottom: 1px solid #e8e8e8;
  transition: 0.3s;
}
.table .thead-dark th {
  color: #000;
  background-color: #fafafa;
  border-color: #454d55;
}
.line:hover {
  background-color: #e6f7ff;
  transition: 0.5s;
}
</style>

