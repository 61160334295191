<template>
	<div>	
    <!-- <button @click="fn"> 45454</button> -->
    <router-link to="/test1" tag="button">5656</router-link>
	</div>		
</template>


<script>
import Vue from 'vue';
import utils from "../utils/utils.js";
export default{
	name:'test'	,
	data(){
		return{
      value:'无关联',
      visible:false,
      modelTitle:'',
      arr:[
        {
          type:'选项关联'
        }
      ],
      arr2:'',
		}
	},
	methods:{
    fn(){

    },
    fn1(){
      let a,b=2;
      return a && b
    }
  }
}
</script>

