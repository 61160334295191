import axios from 'axios'

const gatewayUrl = "/api/"
function checkStatus(response) {
  // loading
  // 如果http状态码正常，则直接返回数据
  if (response && (response.status === 200 || response.status === 304 || response.status === 400 ||  response.status === 401)) {
  //   if(response.status == 401){
      
  //     localStorage.clear();
  //     sessionStorage.clear();
  //     window.location.href = '/#/login';
  // }
    return response;
    // 如果不需要除了data之外的数据，可以直接 return response.data
  }
  // 异常状态下，把错误信息返回去
  // return {
  //   status: -404,
  //   msg: '系统出小差了,请稍后再试~'
  // }
}

// function checkCode(res) {
//   // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
//   if (res.status === -404) {
//     console.log('404', '网络异常')
//   }
//   if (res.data && (!res.data.success)) {

//   }
//   return res
// }

const util={

  gatewayUrl: gatewayUrl,

    checkphone(n) {//校验手机号
        let myreg = /^(((14[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(13[0-9]{1})|(19[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
        if (n && myreg.test(n)) {
          return true
        } else {
          return false
        }
      },
  
      //post请求
  postJson(url, data, header) {
    let _url = '';
    if (url.indexOf('http://') != -1 || url.indexOf('https://') != -1) {
      _url = url
    } else {
      _url = gatewayUrl + url
    }
    const headers = header || {};
    // console.log(header)
    // debugger
    return axios({
      method: 'post',
      url: _url,
      data: data,
      timeout: 3000,
      headers: Object.assign({
        'X-Requested-With': 'XMLHttpRequest',//如果requestedWith为null，则为同步请求。如果requestedWith为XMLHttpRequest则为Ajax请求
        'Content-Type': 'application/json; charset=UTF-8'
      }, headers)
    }).then(
      (response) => {
        // return checkStatus(response)
        return response.data
      }
    ).catch(
      (response)=>{
        // console.log(response.response);
        let res = response.response;
        if(res.status == 401){
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = '/#/login';
        }
        return response.response;
      }
    )
  },

  //get 请求
  get(url, params, header) {
    const headers = header || {}
    let _url = '';
    if (url.indexOf('http://') != -1 || url.indexOf('https://') != -1) {
      _url = url
    } else {
      _url = gatewayUrl + url
    }
    return axios({
      method: 'get',
      url: _url,
      params, // get 请求时带的参数
      timeout: 3000,
      headers: Object.assign({
        'X-Requested-With': 'XMLHttpRequest'
      }, headers)
    }).then(
      (response) => {
        // return checkStatus(response)
        return response.data
      }
    ).catch(
      (response)=>{
        // console.log(response.response);
        let res = response.response;
        if(res.status == 401){
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = '/#/login';
        }
        return response.response;
      }
    )
  },

    combineFromParamForNull: (param) => { //组合参数
      const params = {};
      for (let i in param) {
        if (param[i] != null && param[i] != 'null') {
          params[i] = param[i]
        }
      }
      return params
    },
}



  export default util