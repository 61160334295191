import Vue from 'vue'//导入Vue
import Router from 'vue-router'//导入路由器
import Home from "../pages/home.vue"//首页
import Point from "../pages/point.vue"//指向
import Assessment from "../pages/assessment.vue"//评估
import Diagnose from "../pages/diagnose.vue"//诊断
import Intervene from "../pages/intervene.vue"//干预
import InterContent from "../pages/interContent.vue"//干预
import Visit from "../pages/visit.vue"//随访
import VisitContent from "../pages/visitContent.vue"//随访
import Question from "../pages/question.vue"//问卷
import IllnessDetail from "../pages/illnessDetail.vue"//诊断内容
import Test from "../pages/test.vue"
import Test1 from "../pages/test1.vue"
import Preview from "../pages/preview.vue"//预览
import Connect from "../pages/connect.vue"//预览
import Login from "../pages/login.vue"//预览



Vue.use(Router)//将路由器对象合并到vue对象上

//声明一个routes对象用于配置页面路径
const routes =[
	{
		path:'/',//应用默认要显示的页面
		name:'Home',
		component:Home,
		show:true,
		title:'服务流程',
		type:1,
		icon:'user',
		meta:{
			requireAuth: true
		  }
	  
	},
	{
		path:'/home',//应用默认要显示的页面
		name:'Home',
		component:Home,
		type:1,
		meta:{
			requireAuth: true
		  }
	},
	{
		path:'/point',//指向页面
		name:'Point',
		component:Point,
		type:1,
		meta:{
			requireAuth: true
		  }
	},
	{
		path:'/assessment',//评估
		name:'Assessment',
		component:Assessment,
		show:true,
		title:'评估',
		type:2,
		icon:'experiment',
		meta:{
			requireAuth: true
		}
	},
	{
		path:'/preview',//问题列表
		// name:'Preview',
		component:Preview,
		type:2,
		meta:{
			requireAuth: true
		  }
	},
	{
		path:'/question',//修改问题
		name:'Question',
		component:Question,
		type:2,
		meta:{
			requireAuth: true
		  }
	},
	// {
	// 	path:'/questionCreated',//修改问题
	// 	name:'Question',
	// 	component:Question,
	// 	type:2,
	// 	meta:{
	// 		requireAuth: true
	// 	  }
	// },
	{
		path:'/diagnose',//诊断
		name:'Diagnose',
		component:Diagnose,
		show:true,
		title:'诊断',
		type:3,
		icon:'medicine-box',
		meta:{
			requireAuth: true
		  }
	},
	{
		path:'/illnessDetail',//诊断明细
		name:'IllnessDetail',
		component:IllnessDetail,
		type:3,
		meta:{
			requireAuth: true
		  }
	},
	{
		path:'/intervene',//干预
		name:'Intervene',
		component:Intervene,
		show:true,
		title:'干预',
		type:4,
		icon:'audit',
		meta:{
			requireAuth: true
		  }
	},
	{
		path:'/interContent',//干预内容
		name:'InterContent',
		component:InterContent,
		type:4,
		meta:{
			requireAuth: true
		  }
	},
	{
		path:'/visit',//随访
		name:'Visit',
		component:Visit,
		show:true,
		title:'随访',
		type:5,
		icon:'history',
		meta:{
			requireAuth: true
		  }
	},
	{
		path:'/visitContent',//随访明细
		name:'VisitContent',
		component:VisitContent,
		type:5,
		meta:{
			requireAuth: true
		  }
	},
	{
		path:'/test',//随访
		name:'Test',
		component:Test
	},
	{
		path:'/connect',//关联区间
		name:'Connect',
		component:Connect
	},
	{
		path:'/login',//登录
		name:'Login',
		component:Login
	},
	{
		path:'/test1',//登录
		name:'Test1',
		component:Test1
	},
]

//创建路由器实例
const router = new Router({
	linkActiveClass:'active-nav	',//配置路由激活样式
	routes,
	mode: 'history',
})


const originalPush =Router.prototype.push//解决重复路由冗余问题
   Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

router.onError((error) => {//解决路由跳转之后马上回到首页的问题
	const pattern = /Loading chunk (\d)+ failed/g;
	const isChunkLoadFailed = error.message.match(pattern);
	const targetPath = router.history.pending.fullPath;
	if (isChunkLoadFailed) {
	  router.replace(targetPath);
	}
  });

//导出router实例
export default router