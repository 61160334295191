<template>
  <div>
    <a-button class="editable-add-btn" @click="visible = true">
      添加评估
    </a-button>
    <a-modal
      title="添加评估"
      :visible="visible"
      @ok="addOk"
      @cancel="cancelfn"
      okText="确定"
      cancelText="取消"
    >
      <div class="global-search-wrapper" style="width: 300px">
        <a-auto-complete
          class="global-search"
          size="large"
          style="width: 100%"
          placeholder="请输入关键字"
          option-label-prop="title"
          allowClear
          v-model="nowInputTxt"
          @search="handleSearch"
        >
          <template slot="dataSource">
            <a-select-option
              @click="copyQId(item)"
              v-for="item in dataSource2"
              :key="item.name"
              :title="item.name"
            >
              <span className="global-search-item-count">{{ item.name }}</span>
            </a-select-option>
          </template>
        </a-auto-complete>
      </div>
    </a-modal>

    <a-table bordered :data-source="dataSource" :columns="columns">
      <template slot="name" slot-scope="text, record">
        <editable-cell
          :text="text"
          @change="onCellChange(record.key, 'name', $event)"
        />
      </template>
      <template slot="operate" slot-scope="text, record">
        <a-space>
          <a-button
            @click="push(record.key)"
            type="primary"
            shape="circle"
            icon="eye"
          ></a-button>
          <a-popconfirm
            title="您确定要删除该问卷吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="() => onDelete(record.key)"
          >
            <a-button type="danger" shape="circle" icon="delete"></a-button>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
  </div>
</template>
<script>
const EditableCell = {
  template: `
      <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
          <a-input :value="value" @change="handleChange" @pressEnter="check" /><a-icon
            type="check"
            class="editable-cell-icon-check"
            @click="check"
          />
        </div>
        <div v-else class="editable-cell-text-wrapper">
          {{ value || ' ' }}
          <a-icon type="edit" class="editable-cell-icon" @click="edit" />
        </div>
      </div>
    `,
  props: {
    text: String,
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};
import Bus from "../API/bus.js";
import utils from "../utils/utils.js";
export default {
  components: {
    EditableCell,
  },
  data() {
    return {
      timer: null,
      // showArrow:false,
      userInfo: JSON.parse(localStorage.getItem("userInfo")), //获取token
      visible: false,
      newTitle: "",
      dataSource: [
        {
          key: "",
          name: "",
          age: "",
          address: "",
        },
      ],
      count: 1,
      columns: [
        {
          title: "问卷名",
          dataIndex: "name",
          width: "30%",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "创建者",
          dataIndex: "created_by",
        },
        {
          title: "创建时间",
          dataIndex: "created_at",
        },
        {
          title: "更新时间",
          dataIndex: "updated_at",
        },
        {
          title: "状态",
          dataIndex: "state_display",
          scopedSlots: { customRender: "state" },
        },
        {
          title: "操作",
          dataIndex: "operate",
          scopedSlots: { customRender: "operate" },
        },
      ],
      value2: "",
      dataSource2: [],
      copyQsnaire: null,
      nowInputTxt: "",
    };
  },
  created() {
    this.getList();
    Bus.$off("evaluate");
  },
  mounted() {
    Bus.$on("evaluate", (data) => {
      this.getList();
    });
  },
  methods: {
    setQuesitonnaireID(id) {
      this.questionnaire_id = id;
    },
    push(id) {
      let a = this.dataSource.filter((item) => item.id == id)[0];
      let node = {
        id: a.id,
        title: a.title,
        phase: a.phase,
        type_name: "(评估)",
      };
      this.$store.state.nodeList.push(node);
      // this.$router.push('/preview&'+id)
      this.$router.push("/preview?routine_id=" + id);
    },
    //获取列表
    getList() {
      let that = this;
      let nodeList = JSON.parse(JSON.stringify(this.$store.state.nodeList));
      let data = {
        phase: "evaluate",
      };
      if (nodeList.length > 0) {
        let node = nodeList.pop();
        data.parentId = node.id;
      }
      utils
        .get(`/routine/v2/routine/list`, utils.combineFromParamForNull(data), {
          Authorization: "Bearer " + this.userInfo.access_token,
        })
        .then((res) => {
          if (res.code == 200) {
            this.dataSource = res.data;
            this.dataSource.map((item) => {
              item.key = item.id;
              item.name = item.title;
              return item;
            });
          } else {
            that.$message.error(res.msg);
          }
        });
    },

    onCellChange(key, dataIndex, value) {
      let that = this;
      const dataSource = [...this.dataSource];
      const target = dataSource.find((item) => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
      }
      //遍历数组，修改title
      this.dataSource = this.dataSource.map((item) => {
        if (item.id == key) {
          item.title = value;
          utils
            .postJson(
              `/routine/v2/routine/update`,
              utils.combineFromParamForNull({
                id: key,
                title: value,
                phase: "evaluate",
              }),
              { Authorization: "Bearer " + this.userInfo.access_token }
            )
            .then((res) => {
              if (res.code == 200) {
                that.$message.success("已修改");
              } else {
                that.$message.error(res.msg);
              }
            });
        }
        return item;
      });
    },
    onDelete(key) {
      let that = this;
      const dataSource = [...that.dataSource];
      that.dataSource = dataSource.filter((item) => item.key !== key);
      utils
        .postJson(
          `/routine/v2/routine/delete`,
          utils.combineFromParamForNull({
            id: key,
          }),
          { Authorization: "Bearer " + that.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("已删除");
          } else {
            that.$message.error(res.msg);
          }
        });
    },
    cancelfn() {
      this.visible = false;
      this.dataSource2 = "";
    },
    //确认添加评估
    addOk() {
      let that = this;
      let newTitle = this.nowInputTxt.replace(/\s*/g,"")
      if(this.dataSource.findIndex(it => it.title == newTitle) > -1){
        that.$message.error('已有同名流程');
        return
      }
      if (!that.nowInputTxt) {
        that.$message.warn("请输入名称");
        return;
      }

      let parent_id;
      let nodeList = JSON.parse(JSON.stringify(this.$store.state.nodeList));

      if (nodeList.length > 0) {
        let node = nodeList.pop();
        parent_id = node.id;
      }

      let params = {
        parent_id: parent_id || 0,
        title: that.nowInputTxt,
        phase: "evaluate",
      };

      let isCopy =
        that.copyQsnaire && that.copyQsnaire.name == that.nowInputTxt
          ? true
          : false;

      if (isCopy) {
        params.data = { questionnaire_id: that.copyQsnaire.id };
      }

      that.createdProcess(params, isCopy);
      that.visible = false;
    },
    createdProcess(params, isCopy) {
      //创建评估节点
      let that = this;
      utils
        .postJson(
          `/routine/v2/routine/create`,
          utils.combineFromParamForNull(params),
          { Authorization: "Bearer " + that.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            if (isCopy) {
              that.$message.success("创建成功");
              that.getList();
            } else {
              that.createdBlankQuest(res.data.id);
            }
          } else {
            that.$message.error(res.msg);
          }
        });
    },
    createdBlankQuest(id) {
      //创建一个空白问卷
      let that = this;
      utils
        .postJson(
          `/routine/v2/routine/questionnaire/create`,
          utils.combineFromParamForNull({ routine_id: id }),
          { Authorization: "Bearer " + that.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("创建成功");
            that.getList();
          } else {
            that.$message.error(res.msg);
          }
        });
    },
    clearTimer() {
      if (this.timer) {
        //清理定时器
        clearTimeout(this.timer);
      }
    },
    handleSearch(value) {
      if (value == "") {
        this.dataSource2 = [];
        return;
      }
      //输入框变化
      let that = this;
      that.clearTimer();
      that.nowInputTxt = value;
      that.timer = setTimeout(() => {
        if (that.nowInputTxt) {
          that.getQsList(value);
        }
      }, 500);
    },
    getQsList(value) {
      //获取模板中的问卷列表
      let that = this;
      utils
        .get(
          `/routine/v2/routine/questionnaire/list`,
          utils.combineFromParamForNull({ keywords: value }),
          { Authorization: "Bearer " + that.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            that.dataSource2 = res.data;
          } else {
            that.$message.error(res.msg);
          }
        });
    },
    copyQId(e) {
      //存储选中的问卷
      this.copyQsnaire = e;
      this.nowInputTxt = e.name;
    },
  },
  watch: {},
  computed: {
    nowRouter: function () {
      return this.$route.path;
    },
  },
};
</script>
<style scoped="scoped">
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}

/deep/.ant-input {
  width: 90% !important;
}
</style>

<style>
.ant-table-pagination.ant-pagination {
  display: none;
}
.search-text {
  color: red;
}
</style>
