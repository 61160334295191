<template>
	<div>
		<ul>
			<li 
				@click="btnClick(index)" 
				v-for="(item,index) in movies" 
				:key="index" 
				:class="currentIndex==index?'active':''" 
			>
				{{item.title}}
			</li>
		</ul>
		
		
	</div>
</template>

<script>
	// import bus from '../components/utils/bus.js'
	export default{
		name:"point",
		data(){
			return{
				arr:['2'],
				movies:[
					{
						title:'评估',
						path:'/assessment'
					},
					{
						title:'诊断',
						path:'/diagnose'
					},
					{
						title:'干预',
						path:'/intervene'
					},
					{
						title:'随访',
						path:'/visit'
					},
				],
				                //这isActive为0(也可以改写为false)
				isActive: 0,
				//这currentIndex就是数组中第一个(贰零贰壹)为默认样式(字体为红色)
				currentIndex: 0
			}
		},
		methods:{
			changeIndex(){
				this.$store.commit('changeIndex',this.arr)
			},
			btnClick: function(index) {
				this.currentIndex = index;
				this.$router.replace(this.movies[index].path)
			}	
		},
		computed:{
			 
			  
		}
	}
	
</script>

<style scoped="scoped">
	ul{
		list-style-type: none;
		width: 80%;
		margin: 0 auto;
		display: flex;
		justify-content:space-around;
		margin-top: 200px;
	}
	li{
		width: 100px;
		height: 100px;
		border-radius: 50%;
		border: 1px solid #1890ff;
		text-align: center;
		line-height: 100px;
	}
	a{
		color: #000;
		
		line-height: 100px;
	}
	li:hover{
		color: #1890ff;
		background: #e6f7ff;
		border: none;
	}
	li:hover>a{
		color: #1890ff;
	}
	.active{
		color: #1890ff;
		background: #e6f7ff;
		border: none;
	}
	.active a{
		color: #1890ff;
	}
</style>
