var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-space',{attrs:{"direction":"vertical"}},[_c('a-button',{attrs:{"icon":"plus"},on:{"click":function () {
          _vm.flow = true;
          _vm.newTitle = '';
          _vm.isAdd = true;
        }}}),_c('a-space',{attrs:{"direction":"vertical"}},_vm._l((_vm.stepInner),function(item,index){return _c('div',{key:index,staticClass:"group"},[_c('span',{staticStyle:{"display":"inline-block","width":"300px","cursor":"pointer"},on:{"click":function($event){return _vm.push(item)}}},[_vm._v(_vm._s(item.title))]),_c('a-space',[_c('a-button',{attrs:{"type":"primary","icon":"eye","shape":"circle"},on:{"click":function($event){return _vm.push(item)}}}),_c('a-button',{attrs:{"type":"primary","icon":"edit","shape":"circle"},on:{"click":function () {
                _vm.flow = true;
                _vm.newTitle = item.title;
                _vm.isAdd = false;
                _vm.nowItem = item;
              }}}),_c('a-popconfirm',{attrs:{"title":"您确定要删除该流程吗?","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function($event){return _vm.remove(item)}}},[_c('a-button',{attrs:{"type":"danger","icon":"delete","shape":"circle"}})],1)],1)],1)}),0)],1),_c('a-modal',{attrs:{"title":_vm.isAdd ? '增加流程' : '修改流程名称',"visible":_vm.flow,"confirm-loading":_vm.confirmLoading,"cancelText":"取消","okText":"确定"},on:{"ok":_vm.confirm,"cancel":function () { return (_vm.flow = false); }}},[_c('a-input',{attrs:{"onfocus":"this.select()","placeholder":"请输入流程名称"},model:{value:(_vm.newTitle),callback:function ($$v) {_vm.newTitle=$$v},expression:"newTitle"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }