<template>
  <div id="app">
	<!-- 头部start -->
	<a-page-header
		style="border: 1px solid rgb(235, 237, 240);"
		title="服务流程管理系统"
		sub-title=""
		:ghost="false"		
	/>
	<!-- 头部end -->

	<div v-if="($route.path=='/login')">
		<router-view></router-view>
	</div>
	<div v-if="!($route.path=='/login')">	
		<a-layout id="components-layout-demo-custom-trigger">
			<a-layout-sider v-model="collapsed" :trigger="null" collapsible>	
				<div class="logo" />

				<a-menu theme="light" mode="inline" 
				:default-selected-keys='$store.state.current' 
				:selectedKeys="$store.state.current"
				breakpointsss
				>
					<a-menu-item v-for="item in menuList" 
					:key="item.path" @click="linkTo(item)">
						<a-icon :type="item.icon" />	
						<span>{{item.title}}</span>
					</a-menu-item>
				</a-menu>
			</a-layout-sider>	
		<a-layout>

		<a-layout-header style="background: #fff; padding: 0;position: relative;">
			<a-icon
			class="trigger"
			:type="collapsed ? 'menu-unfold' : 'menu-fold'"
			@click="() => (collapsed = !collapsed)"
			/>

			<span v-if="$store.state.nodeList.length>0">
				<span v-for="(item,index) in $store.state.nodeList" :key="index">
					{{item.title+''+item.type_name}}
					<span v-if="!(index == $store.state.nodeList.length - 1)">></span>
				</span>
			</span>
			<span v-else-if="$store.state.nodeList.length == 0">				
				{{
					$route.path == '/' ? '所有服务流程' : 
					$route.path == '/assessment' ? '所有评估' : 
					$route.path == '/diagnose' ? '所有诊断' :
					$route.path == '/intervene' ? '所有干预' :
					$route.path == '/visit' ? '所有随访' : ''
				}}
			</span>				
			<br />
		</a-layout-header>


		<!-- 内容s -->
		<a-layout-content
			:style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px', minWidth: '1000px' }"
		>
			<!-- 所有在router里配置的页面都在这里 -->
			<router-view></router-view>
			
		</a-layout-content>
		<!-- 内容e -->
		
		</a-layout>
	</a-layout>
	</div>
  </div>
</template>

<script>
	import Point from './pages/point.vue'
	import Bus from './API/bus.js'

export default {
  	name: 'App',
	components: {Point},
	data(){
		const { lang } = this.$route.params;
		return{
			collapsed: false,
			arr:['2'],
			menuList:'',//要显示的菜单列表
			nowRoute:'',//当前页面 路由
			current:['3'],
			basePath: '',
			routes:'',
		}
	},
	methods:{
		redirecTo(e,path){
			let arr1 = this.count;
			let val = true,idx='-1';
			let arr = arr1.reduce((it,item,index) => {
				if(item.path==path){
					idx=index+1;
				}
				if(idx==index){
					val = false;
				}
				if(val){
					return [...it,item]
				}else{
					return it
				}
			},[])
			this.$store.commit('clickReset',arr)
			this.$router.push(path)
		},
		getData(){
			this.menuList=this.$router.options.routes.filter(item=>item.show==true)
			this.routes=this.menuList
			this.nowRoute=this.$route.path;
		},
		setDdefaultPath(){//将当前路由赋值给vuex中的current
			const defaultPath=this.$route.path
			this.$store.commit('to',defaultPath)
		},
		linkTo(item){					
			let arr = []
			this.$store.commit('clickReset',arr)
			this.$router.push(item.path);
			this.$store.state.nodeList = [];
			switch(item.name){
				case "Home":
					item.phase = null;
					break;
				case "Assessment":
					item.phase = 'evaluate';
					Bus.$emit('evaluate','');
					break;				
				case "Diagnose":
					item.phase = 'diagnose';
					Bus.$emit('diagnose','');
					break;
				case "Intervene":
					item.phase = 'intervention';
					Bus.$emit('intervention','');
					break;
				case "Visit":
					item.phase = 'accompany';
					Bus.$emit('accompany','');
					break;
			}
		},
		change(nowPath){
			let path;
			let arr = [
				['/','home','point'],
				['/assessment','preview','question','connect'],
				['/diagnose','illnessDetail'],
				['/intervene','interContent'],
				['/visit','visitContent'],
				['/login']
			];
			arr.forEach(item=>{
				let a = item.some(it => nowPath.indexOf(it)>-1)
				a ? path=item[0] : '';
			})
			this.$store.commit('change',path)
		},
		goBack(){			
			switch(this.$route.path){
				case '/' :
					this.$store.state.nodeList.pop()
					break;
				case '/point' :
					this.$store.state.nodeList.pop()
					break;
				case '/assessment' :
					this.$store.state.nodeList.pop()
					Bus.$emit('evaluate','');
					break;
				case '/diagnose' :
					this.$store.state.nodeList.pop()
					Bus.$emit('diagnose','')
					break;
				case '/intervene' :
					this.$store.state.nodeList.pop()
					Bus.$emit('intervention','');
					break;
				case '/visit' :
					this.$store.state.nodeList.pop()
					Bus.$emit('accompany','');
					break;
				
			}			
		}
	},
	created() {
		this.getData();
		this.setDdefaultPath()
		this.change(this.nowRoute)//在该时机调用，用于解决页面手动刷新菜单栏默认值为空的问题
	},
	watch:{
		$route(nVal){
			this.change(nVal.path)
		},
	},
	computed:{
		count(){
			return this.$store.state.routes
		}
	},
	beforeMount() {
		window.parentMounted = this._isMounted	// _isMounted是当前实例mouned()是否执行 此时为false
	},
	mounted () {
		if (window.history && window.history.pushState) {
			history.pushState(null, null, document.URL)
			window.addEventListener('popstate', this.goBack, false)
		}
	},
	
	destroyed () {
		window.removeEventListener('popstate', this.goBack, false)
	}
}
</script>

<style scoped="scoped">
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html,body{
	height: 100%;
}
#app {
	height: 100%;
}
.ant-page-header{
	height: 10%;
}
.ant-layout-has-sider{
	height: 100%;
}
.ant-layout-sider{
	background-color: #fff;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  /* height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px; */
}
a{
	color: #000;
	text-decoration: none;
	display: block;
	line-height: 45px;
	padding-left: 30px;
	border-bottom: 1px solid #c1c1c1;
}
a:hover{
	color:#fff ;
	
}
.active-nav{
	background-color: rgba(0,0,0,0)!important;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
	background-color: #000!important;
}
.ant-page-header-heading-title{
	color: #fff!important;
}
.breadcrumb{
	position: absolute;
	height: 64px;
	left: 60px;
	top: 0;
	line-height: 64px;
}
.breadcrumb a{
	display: inline-block;
	border-bottom: none;
	padding-left: 0;
	line-height: 64px;
}
.ant-breadcrumb{
	display: inline-block;
}
.ant-breadcrumb a{
	display: inline-block;
	border-bottom:none;
	padding-left:0;
}

</style>
