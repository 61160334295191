import Vue from 'vue'//导入Vue框架
import Vuex from 'vuex'//导入vuex

Vue.use(Vuex)//将vuex挂载到vue上



//声明常量state
/**
 * state 
 * 用于初始化状态
 */
const state={
	current:[],
	par_id:'',
	routes:[],
	nodeList:[],
	unSaveNode:null,
}
/**
 * getters
 * 用于存储state中状态的get方法
 * 访问state对象中定义的状态必经之路
 */
const getters={
	//可不写
	// cartNum(){
	// 	return state.cartNum
	// }
}
/**
 * mutations
 * 用于存储修改state中状态的方法
 * 同步方法，不能在这些方法中异步请求数据
 */
const mutations={
	change(state,path){
		this.state.current=[path]
	},
	to(state,defaultPath){
		this.state.current=[defaultPath]
	},
	changeId(state,id){
		this.state.par_id=id
	},
	clickReset(state,arr){
		this.state.routes=arr
	}
}
/**
 * actions
 * 用于存储修改state中状态的方法
 * 异步:1.不能直接在这些方法中更新state状态，需要调用mutaions中方法完成最终更新
 * 2.可发送异步请求
 */
const actions={
	setCartNumAsnyc(content,param){
		// this.axios({
		// 	url:'接口'
		// }).then(res=>{
		// 	content.commit("setCartNum",param)
		// })
	}
}
//声明store仓库实例
const store = new Vuex.Store({
	state,
	getters,
	mutations,
	actions
})

export default store//导出仓库