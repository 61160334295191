import Vue from 'vue'
import App from './App.vue'
import store from './store/index.js' //引入状态管理器
// import axios from 'axios'
// import Vueaxios from "vue-axios"//引入axios
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './assets/iconfont/iconfont.css' //引入矢量图
import router from './router/index.js' //引入router实例

Vue.config.productionTip = false;
Vue.use(Antd);

router.beforeEach((to, from, next) => {
	if (to.matched.some((auth) => auth.meta.requireAuth)) {
		let userId = JSON.parse(localStorage.getItem('userInfo'));
		if (userId) {
			next();
		} else {
			next({
				path: "/login"
			});
		}
	} else {
		next();
	}
})




new Vue({
	router, //将router实例挂载到vue实例上
	store,
	render: h => h(App),
}).$mount('#app')