
<template>
  <div class="Home">
    <router-link to="/test" tag="button">去test</router-link></br>
    {{msg1}}</br>
    {{msg2}}</br>
    {{msg3}}</br>
    <keep-alive>
    <router-view>
      
    </router-view></keep-alive>
  </div>
</template>
 
<script>
import utils from "../utils/utils.js";
export default {
  name: 'test1',
  data() {
    return {
      msg1:'李焕英',
      msg2:'李焕英',
      msg3:'李焕英',
    }
  },
  methods: {

  },
  created(){
    this.msg1='created'
  },
  mounted(){
    this.msg2='mounted'
  },
  activated(){
    this.msg3='activated'
  }
}
</script>
 
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.search-text {
  color: red;
}
</style>
