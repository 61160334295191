<template>
	<div>
		<div class="detail">
			<div class="name">
				<a-button class="btn">名称</a-button>
				<a-input  class='input' placeholder="Basic usage" />
			</div>
			<div class="content">
				<a-button class="btn">内容</a-button>
				<a-textarea placeholder="Basic usage" :rows="4" />
			</div>
			<div class="about">
				<a-button class="btn">参考素材</a-button>
				<a-input class='input' placeholder="Basic usage" />
			</div>
			<div class="radioGroup">
				<a-button class="btn">关联</a-button>
				<a-input class='input' v-model="modelName" placeholder="Basic usage" />
			</div>
			<div class="radio">
				<a-radio-group v-model='value' @change="onChange">
				   <a-radio :value="1">
				     评估
				   </a-radio>
				   <a-radio :value="2">
				     诊断
				   </a-radio>
				   <a-radio :value="3">
				     干预
				   </a-radio>
				   <a-radio :value="4">
				     随访
				   </a-radio>
				 </a-radio-group>
			</div>
			<a-button class="submit" type="primary" @click="fn()">
			      提交
			</a-button>
		</div>	
	</div>
</template>

<script>
	import utils from "../utils/utils.js";
	export default{
		name:'interContent',
		data(){
			return{
				modelName:'',
				value:1,
				path:'',
				phase:'',
				detailPath:'',
				userInfo:JSON.parse(localStorage.getItem('userInfo')),//获取token
			}
		},
		methods:{
			onChange(e){
				//  console.log('56565', e.target.value);
			},
			fn(){
				let that =this
				switch(this.value){
					case 1:
					this.model='评估';
					this.path='/assessment';
					this.phase='evaluate';
					this.detailPath='/preview/'
					break;
					case 2:
					this.model='诊断';
					this.path='/diagnose';
					this.phase='diagnose';
					this.detailPath='/illnessDetail/'
					break;
					case 3:
					this.model='干预';
					this.path='/intervene';
					this.phase='intervention';
					this.detailPath='/interContent/'
					break;
					case 4:
					this.model='随访';
					this.path='/visit';
					this.phase='accompany';
					this.detailPath='/visitContent/'
					break;
				}
				 utils
					.postJson(
					`/routine/v2/routine/create`,
					utils.combineFromParamForNull(
						{
						parent_id:this.parent_id,
						title: this.modelName,           
						phase:this.phase
						}
					),
					{ Authorization: "Bearer " + this.userInfo.access_token }
					)
					.then((res) => {
					if (res.code == 200) {
						this.$router.push(this.path)
					} else {
						that.$message.error(res.msg);
					}
					});
						},
					},
		computed:{
				parent_id:function(){
					let that = this;
					console.log(that.$route.query.id)
					return that.$route.query.id;
				}
			}
	}
</script>

<style scoped="scoped">
	.detail{
		width: 80%;
		min-width: 600px;
		margin: 0 auto;
		padding: 40px 0 0 0;
	}
	.name,.content,.about{
		width: 100%;
	}
	.name,.content,.about,.radioGroup{
		margin-bottom: 40px;
	}
	.radio{
		margin-left: 20%;
		padding-left: 10px;
		margin-bottom: 40px;
	}
	.btn{
		width: 20%;
		min-width: 60px;
		margin-right: 10px;
	}
	.ant-input{
		width: 70%;
	}
	.submit{
		width:60px;
		margin: 0 auto;
		display: block;
	}
</style>
