<template>
  <div>
    <!-- 添加流程按钮 -->
    <a-space direction="vertical">
      <a-button
        icon="plus"
        @click="
          () => {
            flow = true;
            newTitle = '';
            isAdd = true;
          }
        "
      />

      <a-space direction="vertical">
        <div class="group" v-for="(item, index) in stepInner" :key="index">
          <span
            @click="push(item)"
            style="display: inline-block; width: 300px; cursor: pointer"
            >{{ item.title }}</span
          >
          <a-space>
            <a-button
              type="primary"
              icon="eye"
              shape="circle"
              @click="push(item)"
            ></a-button>
            <a-button
              type="primary"
              icon="edit"
              shape="circle"
              @click="
                () => {
                  flow = true;
                  newTitle = item.title;
                  isAdd = false;
                  nowItem = item;
                }
              "
            ></a-button>

            <a-popconfirm
              title="您确定要删除该流程吗?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="remove(item)"
            >
              <a-button type="danger" icon="delete" shape="circle"></a-button>
            </a-popconfirm>
          </a-space>
        </div>
      </a-space>
    </a-space>

    <!-- 弹窗 -->
    <a-modal
      :title="isAdd ? '增加流程' : '修改流程名称'"
      :visible="flow"
      :confirm-loading="confirmLoading"
      @ok="confirm"
      @cancel="() => (flow = false)"
      cancelText="取消"
      okText="确定"
    >
      <a-input onfocus="this.select()" placeholder="请输入流程名称" v-model="newTitle" />
    </a-modal>
    
  </div>
</template>

<script>
import utils from "../utils/utils.js";
export default {
  name: "edit",
  props: {
    illData: "",
  },
  data() {
    return {
      stepInner: this.illData,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      id: "001",
      newTitle: "",
      ModalText: "Content of the modal",
      visible: false,
      flow: false,
      confirmLoading: false,
      nowTitle: "",
      phase: "",
      modalItem: "",
      isAdd: true,
      nowItem:null,
    };
  },
  methods: {
    confirm() {
      if(!this.newTitle){
        this.$message.warn('流程名称不能为空');
        return
      }
      let newTitle = this.newTitle.replace(/\s*/g,"")
      if(this.illData.findIndex(it => it.title == newTitle) > -1){
        this.$message.error('已有同名流程');
        return
      }
      this.isAdd ? this.add() : this.editOk(this.nowItem);
    },
    push(item) {
      let arr = this.count;
      let obj = {
        "/diagnose": "illnessDetail?id=" + item.id,
        "/intervene": "interContent?id=" + item.id,
        "/visit": "visitContent?id=" + item.id,
      };
      let obj1 = {
        "/diagnose": "(诊断)",
        "/intervene": "(干预)",
        "/visit": "(随访)",
      };
      switch (item.phase) {
        case "diagnose":
          item.type_name = "(诊断)";
          break;
        case "intervention":
          item.type_name = "(干预)";
          break;
        case "accompany":
          item.type_name = "(随访)";
          break;
      }

      let obj2 = {
        path: obj[this.nowRouter],
        title: item.title + obj1[this.nowRouter],
      };
      arr.push(obj2);
      this.$store.commit("clickReset", arr);
      let node = {
        id: item.id,
        title: item.title,
        phase: item.phase,
        type_name: item.type_name,
      };
      this.$store.state.nodeList.push(node);
      this.$router.push(obj[this.nowRouter + ""]);
    },
    add() {
      let that = this;
      let nodeList = JSON.parse(JSON.stringify(this.$store.state.nodeList));
      let data = {
        parent_id: 0,
        title: this.newTitle,
        phase: this.phase,
      };
      if (nodeList.length > 0) {
        let node = nodeList.pop();
        data.parent_id = node.id;
      }
      //添加流程接口
      utils
        .postJson(
          `/routine/v2/routine/create`,
          utils.combineFromParamForNull(data),
          { Authorization: "Bearer " + this.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            this.$emit("getList");
            this.$message.success('创建成功');
          } else {
            that.$message.error(res.data.msg);
          }
        });
      this.flow = false;
    },
    editOk(e) {
      let that = this;
      utils
        .postJson(
          `/routine/v2/routine/update`,
          utils.combineFromParamForNull({
            id: e.id,
            title: this.newTitle,
            phase: this.phase,
          }),
          { Authorization: "Bearer " + this.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("已修改");
            this.$emit("getList");
          } else {
            that.$message.error(res.data.msg);
          }
        });
      this.flow = false;
    },
    editCancel(e) {
      this.visible = false;
    },
    remove(nowitem) {
      let that = this;
      utils
        .postJson(
          `/routine/v2/routine/delete`,
          utils.combineFromParamForNull({ id: nowitem.id }),
          { Authorization: "Bearer " + this.userInfo.access_token }
        )
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("已删除");
            that.$emit("getList");
          } else {
            that.$message.error(res.data.msg);
          }
        });
    },
  },
  mounted() {
    let obj = {
      "/assessment": "evaluate",
      "/diagnose": "diagnose",
      "/intervene": "intervention",
      "/visit": "accompany",
    };
    this.phase = obj[this.nowRouter + ""];
  },
  watch: {
    illData(val) {
      this.stepInner = val;
    },
  },
  computed: {
    nowRouter: function () {
      return this.$route.path;
    },
    count() {
      return this.$store.state.routes;
    },
  },
};
</script>

<style scoped="scoped">
</style>
