<template>
  <div class="container">
    <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
      <a-form-model-item has-feedback label="手机号" prop="phone">
        <a-input v-model.number="ruleForm.phone" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="密码" prop="pass" >
        <a-input @keyup.enter.native="submitForm('ruleForm')" v-model="ruleForm.pass" type="password" autocomplete="off" placeholder="请输入8-18位数至少包含数字和字母" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="submitForm('ruleForm')">
          登录
        </a-button>
        <a-button style="margin-left: 10px" @click="resetForm('ruleForm')">
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
  
</template>
<script>
import { login } from '../API/tool';
import utils from "../utils/utils.js"
import api from '../http/api.js'
export default {
    name:'login',
  data() {
    let checkPhone = (rule, value, callback) => {
        if (!value) {
            return callback(new Error('请输入手机号'));
        }
        if (!(utils.checkphone(value))) {
            callback(new Error('请输入有效手机号'));
        } else {
            callback();
        }
    };
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass: '',
        phone: '',
      },
      rules: {
        phone: [{ validator: checkPhone, trigger: 'change' }],
        pass: [{ validator: validatePass, trigger: 'change' }],
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        let that = this
        if (valid) {
            let parms = {
              phone:that.ruleForm.phone,
              password: that.ruleForm.pass,
                        }
          utils.postJson('web/login',utils.combineFromParamForNull(parms)).then((res) => {
                if (res.code == 200) {
                  localStorage.setItem('userInfo',JSON.stringify(res.data))
                  this.$router.push('/')
                } else {
                  that.$message.error(res.msg);
                }
              })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>

.container{
  width: 500px;
  height: 300px;
  /* border:1px solid #d9d9d9; */
  margin: 10% auto;
  padding: 50px;
  border-radius: 20px;
      z-index: 2;
    -webkit-box-shadow: 10px 15px 30px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    -webkit-transform: translate3d(0,-2px,0);
    transform: translate3d(0,-2px,0);
}

</style>