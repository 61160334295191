<template>
	<div>
		<h1>推荐随访间隔时间</h1>
		 <a-input-number id="inputNumber" v-model="value" :min="1" :max="10" @change="onChange" style="display: inline-block;" />
		 <h1 style="display: inline-block;margin-left: 5px;">天</h1>
	</div>
</template>

<script>
	export default{
		name:'visitContent',
		data(){
			return{
				value:'1',
				path:'',
				phase:'',
				detailPath:'',
			}
		},
		methods: {
		    onChange(value) {
		    //   console.log('changed', value);
		    },
		  },
	}
</script>

<style>
</style>
